import styled, { css } from 'styled-components';

export const HeadingWrapper = styled.div`
        width: 30%; 
        width: 100%;
        display:flex;
        flex-direction: column;
        align-items:${props => props.align==="left" ? "flex-start": "flex-end"};
`;

export const HeadingText = styled.span`
  color: ${props => props.theme.textColor};
  font-family: Arial;
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background: -webkit-linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background: -moz-linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background: -o-linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background-size: 86% 86%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 1.5px transparent;

  ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size: 120px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size: 120px;
        }

        @media (min-width: 1280px) and (max-width: 1365px) {
            /* Add your styles for medium screens here */
            font-size: 120px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size: 120px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size: 110px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size: 110px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size: 84px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size: 64px;
        }
        @media (min-width: 430px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size: 54px;
        }

        @media (min-width: 414px) and (max-width: 430px) {
            /* Add your styles for medium screens here */
            font-size: 54px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size: 50px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size: 50px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size: 44px;
        }
    `}
`;
export const HeadingSubText = styled.span`
    color: ${props => props.theme.textColor};
    font-family: Arial;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size:60px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size:60px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            font-size:60px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size:60px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size:60px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size:50px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size:50px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 430px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 414px) and (max-width: 430px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size:28px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size:28px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size:22px;
        }
    `}
`;

export const SubHeadingWrapper = styled.div`
        margin-top:40px;
        display:flex;
        justify-content:${props => props.align==="left" ? "flex-start": "flex-end"};
        text-align:${props => props.align==="left" ? "left": "right"};
`;

export const SubSubHeadingWrapper = styled.div`
        margin-top:30px;
        display:flex;
        justify-content:${props => props.align==="left" ? "flex-start": "flex-end"};
        text-align:${props => props.align==="left" ? "left": "right"};
`;

export const HeadingSubSubText = styled.span`
        color: ${props => props.theme.subHeadingColor};
        font-family: Arial;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size:40px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size:40px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            font-size:40px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size:40px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size:24px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }

        @media (min-width: 430px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }

        @media (min-width: 414px) and (max-width: 430px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size:18px;
        }
    `}
`;
import React from "react";
import {
    StyledHeadingWrapper,
    StyledHeadingText
} from "./style";

const BannerTOU = ({ langObject, align, text }) => {
    return(
        <StyledHeadingWrapper align={align} >
            <StyledHeadingText align={align}>{text}</StyledHeadingText>
        </StyledHeadingWrapper>
    );
}

export default BannerTOU;

import HomePageAudioEnglish from "../assets/audios/english/1_TheFutureOfTech_EN.mp3"
import CommitmentAudioEnglish from "../assets/audios/english/2_OurCommitment_EN.mp3"
import EmpoweringAudioEnglish from "../assets/audios/english/3_EmpoweringInnovation_EN.mp3"
import PioneersAudioEnglish from "../assets/audios/english/4_PioneersWanted_EN.mp3"
import GetInTouchAudioEnglish from "../assets/audios/english/5_GetInTouchWithUs_EN.mp3"
import LegalAudioEnglish from "../assets/audios/english/6_LegalInformation_EN.mp3"

import HomePageAudioGerman from "../assets/audios/german/1_TheFutureOfTech_DE.mp3"
import CommitmentAudioGerman from "../assets/audios/german/2_OurCommitment_DE.mp3"
import EmpoweringAudioGerman from "../assets/audios/german/3_EmpoweringInnovation_DE.mp3"
import PioneersAudioGerman from "../assets/audios/german/4_PioneersWanted_DE.mp3"
import GetInTouchAudioGerman from "../assets/audios/german/5_GetInTouchWithUs_DE.mp3"
import LegalAudioGerman from "../assets/audios/german/6_LegalInformation_DE.mp3"

import HomePageAudioSpanish from "../assets/audios/spanish/1_TheFutureOfTech_ES.mp3"
import CommitmentAudioSpanish from "../assets/audios/spanish/2_OurCommitment_ES.mp3"
import EmpoweringAudioSpanish from "../assets/audios/spanish/3_EmpoweringInnovation_ES.mp3"
import PioneersAudioSpanish from "../assets/audios/spanish/4_PioneersWanted_ES.mp3"
import GetInTouchAudioSpanish from "../assets/audios/spanish/5_GetInTouchWithUs_ES.mp3"
import LegalAudioSpanish from "../assets/audios/spanish/6_LegalInformation_ES.mp3"

export const AudioFiles = {
    home: {
        english: {
            home: HomePageAudioEnglish,
            commitment: CommitmentAudioEnglish,
            empowering: EmpoweringAudioEnglish,
            pioneers: PioneersAudioEnglish,
            getInTouch: GetInTouchAudioEnglish,
            legal: LegalAudioEnglish,
        },
        spanish: {
            home: HomePageAudioSpanish,
            commitment: CommitmentAudioSpanish,
            empowering: EmpoweringAudioSpanish,
            pioneers: PioneersAudioSpanish,
            getInTouch: GetInTouchAudioSpanish,
            legal: LegalAudioSpanish,
        },
        german: {
            home: HomePageAudioGerman,
            commitment: CommitmentAudioGerman,
            empowering: EmpoweringAudioGerman,
            pioneers: PioneersAudioGerman,
            getInTouch: GetInTouchAudioGerman,
            legal: LegalAudioGerman,
        },
    }
}

export default AudioFiles;

import React from "react";
import {
    StyledContentWrapper,
    StyledText,
    StyledHeading,
    StyledList,
    StyledUl
} from "../style";

const ContentCookiePolicy = ({ langObject }) => {
    return(
        <StyledContentWrapper>
            <StyledText>{langObject.updated}</StyledText>
            <StyledText>{langObject.date}</StyledText>
            <StyledText margin={"25px"} align={langObject.align}>{langObject.welcome}</StyledText>

            <StyledHeading>{langObject.cookieTitle}</StyledHeading>
            <StyledText align={langObject.align }>{langObject.cookieContent}</StyledText>

            <StyledHeading>{langObject.whyUseCookieTitle}</StyledHeading>
            <StyledText align={langObject.align }>{langObject.whyCookieContent}</StyledText>

            <StyledHeading>{langObject.typesTitle}</StyledHeading>
            <StyledUl align={langObject.align}>
                <StyledList>{langObject.typeBullet1}</StyledList>
                <StyledList>{langObject.typeBullet2}</StyledList>
            </StyledUl>

            <StyledHeading>{langObject.controlCookieTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.controlCookieContant}</StyledText>

            <StyledHeading>{langObject.changesCookieTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.changesCookieContant}</StyledText>

            <StyledHeading>{langObject.contactUsTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.contactUsContant}</StyledText>
        </StyledContentWrapper>
    );
}

export default ContentCookiePolicy;
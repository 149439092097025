import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { AudioVisualizer } from 'react-audio-visualize';
import AudioSpectrum from 'react-audio-spectrum';
import Animation from '../../assets/animation.gif';
import PlayButton from "../../assets/svg/PlayButton";
import PauseButton from "../../assets/svg/PauseButton";

import { useTheme } from "styled-components";

const AudioSection = ({ audioFile, align, text }) => {
  const [play, setPlay] = useState(false);
  const theme = useTheme();
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (play) {
      ref.current.play();
  } else {
      ref.current.pause();
  }
  },[play])

  const handleSectionClick = () => {
    setPlay(!play);
  };

  const handleAudioEnd = () => {
    setPlay(false);
  }

  return (
    <div style={{ width:"fi",display:"flex", flexDirection:"row", alignItems:"center", gap:"10px", marginTop:"30px", justifyContent:align==="center"? "center":"flex-start"}}>
      <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"10px"}} onClick={handleSectionClick}>
        {/* <img src={SpeakerSvg} alt='speaker' height={"18px"} width={"20px"} style={{marginTop:"2px"}} /> */}
        <audio src={audioFile} onEnded={handleAudioEnd} ref={ref} preload="auto"></audio>
        {!play && <PlayButton height={"18px"} width={"20px"} fill={theme.textColor} />}
        {/* {!play && (
          <p
            style={{ color: theme.textColor, paddingLeft:"3px", paddingRight:"3px", cursor: 'pointer', fontSize: '25px', margin: '0px', lineHeight:"20px", marginBottom:"2px" }}
          >
            read this section
          </p>
        )} */}
        {play && <PauseButton height={"18px"} width={"20px"} fill={theme.textColor} cursor={"pointer"} />}
        {/* {play && <img src={Animation} style={{ width: '183px', height: '20px', marginBottom:"2px" }} alt={'animation'} />} */}
        <p
            style={{ color: theme.textColor, paddingLeft:"3px", paddingRight:"3px", cursor: 'pointer', fontSize: '25px', margin: '0px', lineHeight:"20px", marginBottom:"2px" }}
          >
            {text}
          </p>
      </div>

    </div>
  );
};

export default AudioSection;

import React from "react";
import PageContainer from "../../components/PageContainer";

import BannerTOU from "../../components/BannerTOU";
import ContentCookiePolicy from "../../components/ContentCookiePolicy";

import useGetLanguage from "../../hooks/useGetLanguage";
import { useLocation } from "react-router-dom";

const CookiePolicy = () => {
  const langObject = useGetLanguage("cookie");
  const location = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, [location.pathname]);
  return (
    <PageContainer page={"cookie"}>
      <BannerTOU langObject={langObject} align={langObject.align} text={"COOKIE POLICY FOR VIZION"} />
      <ContentCookiePolicy langObject={langObject} />
    </PageContainer>
  );
};

export default CookiePolicy;

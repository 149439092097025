import styled, { css } from "styled-components";
import { textAlign, align } from "../../constants";
export const StyledHeadingWrapper = styled.div`
  width: 70%;
  display:flex;
  align-items: ${props => align[props.align]};

`;

export const StyledHeadingText = styled.span`
  color: ${props => props.theme.textColor};
  font-family: Arial;
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: ${props => textAlign[props.align]};
  background: linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background: -webkit-linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background: -moz-linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background: -o-linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background-size: 86% 86%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px transparent;

  ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size: 104px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size: 100px;
        }

        @media (min-width: 1280px) and (max-width: 1365px) {
            /* Add your styles for medium screens here */
            font-size: 96px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size: 84px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size: 74px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size: 72px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size: 64px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size: 60px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size: 56px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size: 54px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size: 52px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size: 48px;
        }
    `}
`;

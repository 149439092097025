import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="0 0 26 29"
    fill="none"
    {...props}
  >
    <path
      d="M24.2785 12.1584L4.14177 0.371997C2.50566 -0.58519 0 0.343678 0 2.71116V26.2784C0 28.4023 2.32831 29.6823 4.14177 28.6175L24.2785 16.8368C26.0748 15.789 26.0806 13.2062 24.2785 12.1584Z"
      fill={props.fill}
    />
  </svg>
);
export default SvgComponent;

import React from "react";
import {
  HeadingWrapper,
  HeadingText,
  SubHeadingWrapper,
  HeadingSubText,
  SubSubHeadingWrapper,
  HeadingSubSubText,
} from "./styles";
import AudioComponent from "../AudioSpectrum";

const HomePageBanner = ({ langObject, audio }) => {
  return (
    <div style={{ marginTop: "50px"}}>
      <HeadingWrapper align={langObject.align}>
        <HeadingText>{langObject.headingText1}</HeadingText>
        <HeadingText>{langObject.headingText2}</HeadingText>
        <HeadingText>{langObject.headingText3}</HeadingText>
      </HeadingWrapper>
      <SubHeadingWrapper align={langObject.align}>
        <HeadingSubText>{langObject.subHeading}</HeadingSubText>
      </SubHeadingWrapper>
      <SubSubHeadingWrapper align={langObject.align}>
        <HeadingSubSubText>{langObject.subsubHeading}</HeadingSubSubText>
      </SubSubHeadingWrapper>
      <AudioComponent audioFile={audio} text={langObject.readThisSection} />
    </div>
  );
};
export default HomePageBanner;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { VizionText, StyledSelect, StickyNav, NavContent } from "./style";
import { languages } from "../constants";

const Navbar = ({page}) => {
  const navigate = useNavigate();
  const [isSticky, setSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      // setSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <StickyNav sticky={isSticky}>
      <NavContent>
        <VizionText sticky={isSticky} onClick={() => navigate("/")}>
          VIZION<span style={{ color: "#0FF" }}>.</span>
        </VizionText>
        <StyledSelect
          sticky={isSticky}
          value={localStorage.getItem("lang")}
          onChange={(e) => {
            localStorage.setItem("lang", e.target.value);
            const storageEvent = new Event("storage");
            window.dispatchEvent(storageEvent);
          }}
        >
          {Object.keys(languages[page]).map((key) => (
            <option value={key}>{languages[page][key].label}</option>
          ))}
        </StyledSelect>
      </NavContent>
    </StickyNav>
  );
};

export default Navbar;

import React from "react";
import PageContainer from "../../components/PageContainer";
import BannerTOU from "../../components/BannerTOU";
import ContentTOU from "../../components/ContentTOU";
import { useLocation } from "react-router-dom";

import useGetLanguage from "../../hooks/useGetLanguage";

const TermsOfUse = () => {
    const langObject = useGetLanguage("terms");
    const location = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, [location.pathname]);
    return(
        <PageContainer page={"terms"}>
            <BannerTOU langObject={langObject} align={langObject.align} text={"TERMS OF USE FOR VIZION"} />
            <ContentTOU langObject={langObject} />
        </PageContainer>
    );
}
export default TermsOfUse;
import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="0 0 29 29"
    fill="none"
    {...props}
  >
    <path
      d="M9.32143 29H3.10714C1.39174 29 0 27.6083 0 25.8929V3.10714C0 1.39174 1.39174 0 3.10714 0H9.32143C11.0368 0 12.4286 1.39174 12.4286 3.10714V25.8929C12.4286 27.6083 11.0368 29 9.32143 29ZM29 25.8929V3.10714C29 1.39174 27.6083 0 25.8929 0H19.6786C17.9632 0 16.5714 1.39174 16.5714 3.10714V25.8929C16.5714 27.6083 17.9632 29 19.6786 29H25.8929C27.6083 29 29 27.6083 29 25.8929Z"
      fill={props.fill}
    />
  </svg>
);
export default SvgComponent;

import React from "react";
import { StyledLink, LinkWrapper } from "./styles";

import {
  MainHeadline,
  MainHeadlineWrapper,
  SubHeadlineWrapper,
  SubHeadline,
} from "../style";
import AudioComponent from "../AudioSpectrum";

const LegalSection = ({ langObject, audio }) => {
  return (
    <>
      <MainHeadlineWrapper align={langObject.align}>
        <MainHeadline align={langObject.align}>{langObject.legalInformationHeadline}</MainHeadline>
      </MainHeadlineWrapper>
      <SubHeadlineWrapper align={langObject.align}>
        <SubHeadline align={langObject.align}>
          {langObject.legalInformationSubHeadline}
        </SubHeadline>
      </SubHeadlineWrapper>
      <LinkWrapper align={langObject.align}>
        <StyledLink align={langObject.align} to={"/terms"} margin={0}>{langObject.termsOfUseText}</StyledLink>
        <StyledLink align={langObject.align} to={"/privacy-policy"} margin={32}>{langObject.privacyPolicyText}</StyledLink>
        <StyledLink align={langObject.align} to={"/cookie-policy"} margin={32}>{langObject.cookiePolicy}</StyledLink>
      </LinkWrapper>
      <AudioComponent text={langObject.readThisSection} audioFile={audio} />
    </>
  );
};

export default LegalSection;

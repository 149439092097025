import styled, { css } from 'styled-components';

export const HomeContainer = styled.div`
    background-color: ${props => props.theme.backgroundColor};
    padding: 80px;
    ::selection {
        color: black;
        background-color: #ffff00;
    }

    ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            padding-top: 70px;
            padding-bottom: 70px;
            padding-left: 64px;
            padding-right: 64px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            padding-top: 70px;
            padding-bottom: 70px;
            padding-left: 56px;
            padding-right: 56px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            padding-top: 60px;
            padding-bottom: 60px;
            padding-left: 48px;
            padding-right: 48px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            padding-top: 50px;
            padding-bottom: 50px;
            padding-left: 40px;
            padding-right: 40px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            padding-top: 40px;
            padding-bottom: 40px;
            padding-left: 30px;
            padding-right: 30px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            padding-top: 50px;
            padding-bottom: 50px;
            padding-left: 40px;
            padding-right: 40px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            padding-top: 32px;
            padding-bottom: 32px;
            padding-left: 24px;
            padding-right: 24px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            padding-top: 30px;
            padding-bottom: 30px;
            padding-left: 24px;
            padding-right: 24px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            padding-top: 28px;
            padding-bottom: 28px;
            padding-left: 20px;
            padding-right: 20px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 16px;
            padding-right: 16px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 16px;
            padding-right: 16px;
        }

        @media (max-width: 360px) {
            /* Add your styles for medium screens here */
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 16px;
            padding-right: 16px;
        }
    `}
`;

export const HeadingWrapper = styled.div`
        margin-top: 48px;
        width: 100%;
        display:flex;
        flex-direction: column;
        align-items:${props => props.align==="left" ? "flex-start": "flex-end"};

        ::selection {
            color: black;
            background-color: #ffff00;
        }
`;

export const HeadingText = styled.span`
  color: ${props => props.theme.textColor};
  font-family: Arial;
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size: 104px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size: 100px;
        }

        @media (min-width: 1280px) and (max-width: 1365px) {
            /* Add your styles for medium screens here */
            font-size: 96px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size: 84px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size: 74px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size: 72px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size: 64px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size: 60px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size: 56px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size: 54px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size: 52px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size: 48px;
        }
    `}
`;
export const HeadingSubText = styled.span`
    color: ${props => props.theme.textColor};
    font-family: Arial;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size:50px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size:48px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            font-size:46px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size:44px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size:42px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size:40px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size:38px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size:36px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size:34px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size:32px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size:32px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }
    `}
`;

export const SubHeadingWrapper = styled.div`
        margin-top:80px;
        display:flex;
        justify-content:${props => props.align==="left" ? "flex-start": "flex-end"};
        text-align:${props => props.align==="left" ? "left": "right"};
        ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            margin-top:70px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            margin-top:68px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            margin-top:96px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            margin-top:56px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            margin-top:50px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            margin-top:48px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            margin-top:42px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            margin-top:40px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            margin-top:38px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            margin-top:36px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            margin-top:34px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            margin-top:32px;
        }
    `}
`;

export const SubSubHeadingWrapper = styled.div`
        margin-top:51px;
        display:flex;
        justify-content:${props => props.align==="left" ? "flex-start": "flex-end"};
        text-align:${props => props.align==="left" ? "left": "right"};
        ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            margin-top:48px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            margin-top:46px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            margin-top:44px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            margin-top:42px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            margin-top:40px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            margin-top:38px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            margin-top:36px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            margin-top:34px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            margin-top:32px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            margin-top:30px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            margin-top:30px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            margin-top:32px;
        }
    `}
`;

export const HeadingSubSubText = styled.span`
        color: ${props => props.theme.subHeadingColor};
        font-family: Arial;
        font-size: 46px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size:44px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size:42px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            font-size:40px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size:38px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size:36px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size:34px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size:32px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size:28px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size:26px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size:26px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size:24px;
        }
    `}
`;

export const SectionSeparator = styled.div`
        height: 0.8px;
        width: 100%;
        margin-top: 100px;
        background-color: ${props => props.theme.textColor}
`;
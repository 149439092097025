import { 
    MainHeadline,
    MainHeadlineWrapper,
    SubHeadlineWrapper,
    SubHeadline,
    SubSubHeadlineWrapper,
    SubSubHeadline
} from "../style";
import AudioComponent from "../AudioSpectrum";
const InnovationsSection = ({ langObject, audio }) => {
    return(
        <div>
            <MainHeadlineWrapper align={langObject.align}>
                <MainHeadline align={langObject.align}>{langObject.innovationHeading || ""}</MainHeadline>
            </MainHeadlineWrapper>
            <SubHeadlineWrapper align={langObject.align}>
                <SubHeadline align={langObject.align}>{langObject.innovationSubHeading || ""}</SubHeadline>
            </SubHeadlineWrapper>
            <SubSubHeadlineWrapper align={langObject.align}>
                <SubSubHeadline align={langObject.align}>{langObject.innovationSubSubHeading1 || ""}</SubSubHeadline>
                <br/>
                <SubSubHeadline align={langObject.align}>{langObject.innovationSubSubHeading2 || ""}</SubSubHeadline>
            </SubSubHeadlineWrapper>
            <AudioComponent text={langObject.readThisSection} audioFile={audio} />
        </div>
    )
}

export default InnovationsSection;

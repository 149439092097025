export const languages = {
  home: {
    english: {
      align: "left",
      label: "English",
      readThisSection: "Read this section",
      headingText1: "THE FUTURE",
      headingText2: "OF TECH",
      headingText3: "IS A GAME.",
      subHeading:
        "Games are One of the Most Important and Timeless Mediums of Human Interaction.",
      subsubHeading:
        "We are building Apps That Reshape How We Interact with Tomorrow's World in a Playful Way.",
      commitmentHeading: "OUR COMMITMENT",
      commitmentSubHeading: "Guided by Virtue, Driven by Purpose",
      commitmentSubSubHeading:
        "Dedicated to a clear purpose, we strive to create innovative apps designed to simplify daily life tasks and empower individuals to manage their challenges with ease. Our work is guided by principles of moral excellence, ensuring honesty and ethical conduct in all our business dealings. This commitment permeates every interaction, to build trust with our users and stakeholders.",
        commitmentSubSubHeading2: "At the core of our mission is a profound desire to make a tangible positive impact, driving us to innovate and contribute meaningfully to society. We look beyond profit, focusing our creativity and expertise on developing solutions that not only improve life quality but also empower users with the tools they need to thrive. With our apps, we want to overcome real challenges and promote a more connected and digitalized global community.",
        innovationHeading: "EMPOWERING INNOVATION",
        innovationSubHeading: "Your Custom Software Solutions",
        innovationSubSubHeading1: "As we extend our mission into new horizons, we introduce a dedicated service for developing custom software solutions. This endeavor is rooted in our commitment to innovation and quality, aiming to transform how businesses and individuals interact with technology. We harness the latest technical advancements to create software that's not only user-centric but also a catalyst for positive change, embodying our vision of a more empowered society.",
        innovationSubSubHeading2: "Each project is a step towards addressing the unique challenges of our times, backed by our expertise and a deep understanding of the digital ecosystem. Through meticulous development and collaborative engagement, we strive to deliver software that exceeds expectations, ensuring our solutions are not just effective but also a beacon of technological excellence and social responsibility.",
        pioneersHeading: "PIONEERS WANTED",
        pioneersSubHeading: "Ready to Engineer the Unseen?",
        pioneersSubSubHeading1: "We seek the bold and the imaginative, those ready to engineer the unseen. Our journey is one of discovery, innovation, and crafting solutions that push the boundaries of what technology can achieve. Together, we master the challenges of today and tomorrow, creating impactful technologies that empower and inspire a global community.",
        pioneersSubSubHeading2: "Your expertise and vision are the keys to unlocking new realms of possibility, driving us towards a future where technology amplifies human potential. We're not just building software; we're reshaping the digital future and invite you to join us.",
      whatsNextHeading: "SEE WHATS NEXT",
      whatsNextSubHeading: "Today's Innovations, Tomorrow's Standards",
      whatsNextSubSubHeading:
        "Explore and discover how we're turning innovative ideas into everyday realities, setting new benchmarks in the app world, and continuously pushing the boundaries of what's possible.",
      contactSectionHeading: "GET IN TOUCH WITH US",
      contactSectionSubHeading: "Let’s Innovate Together",
      ideasText: "Your ideas and investment can shape the future of technology. Whether you're an enthusiastic user, an investor looking to make an impact, or a potential partner, we're excited to hear from you.",
      namePlaceholder: "Your Name",
      emailPlaceholder: "Your Email",
      messagePlaceholder: "Your Message",
      submitButtonText: "WRITE US A MESSAGE",
      privacyText:
        "We respect your privacy. By submitting a message to us, you explicitly agree to our Terms of Use and acknowledge our Privacy Policy & Cookie Policy. Your information will be used to respond to your inquiry and as described in our Privacy Policy. For information on how to withdraw consent or modify your data, please see our Privacy Policy.",
      legalInformationHeadline: "Legal Information",
      legalInformationSubHeadline:
        "Stay Informed About Your Rights and Our Policies",
      termsOfUseText: "Terms of Use",
      privacyPolicyText: "Privacy Policy",
      cookiePolicy: "Cookie Policy",
    },
    spanish: {
      align: "left",
      label: "Español",
      readThisSection: "Lee esta sección",
      headingText1: "EL FUTURO",
      headingText2: "DE LA TECNOLOGÍA",
      headingText3: "ES UN JUEGO.",
      subHeading:
        "Los juegos son uno de los medios más importantes y atemporales de interacción humana.",
      subsubHeading:
        "Desarrollamos aplicaciones que redefinen la forma en que interactuamos con el mundo del mañana de manera lúdica.",
      commitmentHeading: "NUESTRO COMPROMISO",
      commitmentSubHeading: "Guiados por Valores, Impulsados por un Propósito",
      commitmentSubSubHeading:
        "Con un propósito claro en mente, desarrollamos aplicaciones innovadoras que mejoran la vida, empoderan a los individuos y crean un futuro mejor para todos. Nuestro trabajo está guiado por principios de excelencia moral e integridad, asegurando honestidad y conducta ética en todas nuestras transacciones comerciales. Este compromiso se extiende a cada interacción, fomentando la confianza de nuestros usuarios y partes interesadas.",
        commitmentSubSubHeading2:"En el núcleo de nuestra misión yace el profundo deseo de tener un impacto positivo tangible, lo que nos motiva a innovar y contribuir de manera significativa a la sociedad. Miramos más allá del beneficio económico, enfocando nuestra creatividad y experiencia en desarrollar soluciones que no solo mejoren la calidad de vida, sino que también proporcionen a los usuarios las herramientas necesarias para prosperar. Con nuestras aplicaciones, aspiramos a abordar desafíos reales y fomentar una comunidad global más conectada y digitalizada.",
        innovationHeading: "FOMENTANDO LA INNOVACIÓN",
        innovationSubHeading: "Soluciones de Software a Medida",
        innovationSubSubHeading1: "Al extender nuestra misión hacia nuevos horizontes, presentamos un servicio dedicado al desarrollo de soluciones de software a medida. Este empeño se basa en nuestro compromiso con la innovación y la calidad, buscando transformar cómo empresas e individuos interactúan con la tecnología. Aprovechamos los últimos avances técnicos para crear software que no solo esté centrado en el usuario, sino que también sea un motor de cambio positivo, reflejando nuestra visión de una sociedad más empoderada.",
        innovationSubSubHeading2: "Cada proyecto es un paso hacia la superación de los desafíos únicos de nuestro tiempo, respaldado por nuestro conocimiento experto y una comprensión profunda del ecosistema digital. A través de un desarrollo meticuloso y un compromiso colaborativo, nos esforzamos por entregar soluciones de software que superen las expectativas, asegurando que nuestras soluciones no solo sean efectivas, sino que también establezcan estándares de excelencia tecnológica y responsabilidad social.",
        pioneersHeading: "PIONEROS BUSCADOS",
        pioneersSubHeading: "¿Listo para moldear lo desconocido?",
        pioneersSubSubHeading1: "Buscamos a los valientes y a los creativos. Nuestro viaje es uno de descubrimiento, innovación y desarrollo de soluciones que amplían los límites de lo que la tecnología puede lograr. Juntos, superamos los desafíos de hoy y mañana, creando tecnologías impactantes que fortalecen e inspiran a una comunidad global.",
        pioneersSubSubHeading2: "Tu experiencia y visión son la clave para desbloquear nuevos ámbitos de posibilidad, impulsándonos hacia un futuro en el que la tecnología amplifica el potencial humano. No solo estamos construyendo software; estamos redefiniendo el futuro digital y te invitamos a unirte a nosotros.",
      whatsNextHeading: "DESCUBRE LO QUE VIENE A CONTINUACIÓN",
      whatsNextSubHeading: "Innovaciones de Hoy, Estándares del Mañana",
      whatsNextSubSubHeading:
        "EasyConsent es una aplicación familiar que simplifica y agiliza la gestión de consentimientos digitales, garantizando que se respeten la privacidad y las elecciones personales, sean fácilmente controlables y legalmente vinculantes.",
      contactSectionHeading: "CONTÁCTANOS",
      contactSectionSubHeading: "Hablemos sobre tus idea",
      ideasText: "Your ideas and investment can shape the future of technology. Whether you're an enthusiastic user, an investor looking to make an impact, or a potential partner, we're excited to hear from you.",
      namePlaceholder: "Tu Nombre",
      emailPlaceholder: "Tu Correo Electrónico",
      messagePlaceholder: "Tu Mensaje",
      submitButtonText: "ESCRÍBENOS UN MENSAJE",
      privacyText:
        "Respetamos tu privacidad. Al enviarnos un mensaje, aceptas explícitamente nuestros Términos de Uso y reconoces nuestra Política de Privacidad y Política de Cookies. Tu información será utilizada para responder a tu consulta tal como se describe en nuestra Política de Privacidad. Para información sobre cómo retirar el consentimiento o modificar tus datos, por favor consulta nuestra Política de Privacidad.",
      legalInformationHeadline: "INFORMACIÓN LEGAL",
      legalInformationSubHeadline:
        "Mantente informado sobre tus derechos y nuestras políticas",
      termsOfUseText: "Términos de uso",
      privacyPolicyText: "Política de privacidad",
      cookiePolicy: "Política de cookies",
    },
    // urdu: {
    //   align: "right",
    //   label: "اردو",
    //   headingText1: "مستقبل",
    //   headingText2: "ٹیکنالوجی کا",
    //   headingText3: "ایک کھیل ہے۔",
    //   subHeading:
    //     "کھیل ایک سب سے اہم اور دائمی وسیلے میں سے ایک ہے جوانسان کے درمیان تعامل کا۔",
    //   subsubHeading:
    //     "وہ ایپس تخلیق کریں جو ہمیں ہندسہ کریں کہ ہمکل کے دنوں میں کس طرح کھیلے کر رہیں۔",
    //   commitmentHeading: "ہمارا عہد",
    //   commitmentSubHeading: "آپ کے خاندان کی حفاظت",
    //   commitmentSubSubHeading:
    //     "“میں ہم یقین رکھتے ہیں کہ بچے صرف ہمارے دنیا کا اہم حصہ نہیں ہیں؛ بلکہ وہ ہمارے سب سے قیمتی اور امید افزا اثاثے ہیں۔ ہمارا سفر اس خزانے کو حفاظتی اور پرورش دینے میں وابستہ ہے۔ ہم وعدہ کرتے ہیں کہ ہر بچہ، حدود، زات یا معقولیت کے بغیر، ایک محفوظ اور مددگار ماحول میں موجود ہو۔ VIZION کی مشن نیئے تجاویزی ٹیکنالوجی سے زیادہ ہے؛ یہ ہمارے لیے ایک مستقبل بنانے کا عہد ہے جہاں خاندان اور بچے صرف منسلک نہیں ہیں، بلکہ حفاظتی بھی ہیں۔ یہ صرف ایک کاروبار کا مقصد نہیں ہے؛ بلکہ یہ ہمارا وعدہ ہے کہ ہم اپنے دنیا میں قیمتی اور حفاظتی لحاظ سے سب سے زیادہ کمزور اور ضروری اہم افراد - ہمارے خاندان اور بچوں - کی طرف اپنا حصہ ڈالیں گے۔ VIZION”",
    //   contactSectionHeading: "ہم سے رابطہ کریں",
    //   contactSectionSubHeading: "آپ کی خیالات پر بات چیت کریں",
    //   namePlaceholder: "آپ کا نام",
    //   emailPlaceholder: "آپ کا ای میل",
    //   messagePlaceholder: "آپ کا پیغام",
    //   submitButtonText: "جمع کرائیں",
    //   privacyText:
    //     "ہم آپکی خصوصیت کا احترام کرتے ہیں۔ اس فارم کو جمع کرانے کے ذریعے، آپ ہمارے استعمال کے ضوابط کو صریحاً قبول کرتے ہیں اور ہماری پرائیویسی پالیسی اور کوکی پالیسی کا اقرار کرتے ہیں۔ آپ کی معلومات آپکی سوالات کا جواب دینے اور ہماری پرائیویسی پالیسی میں بیان کی گئی مدد کے طور پر استعمال ہو گی۔ رضاکارانہ ہونے یا آپکی ڈیٹا کو ترتیب دینے کے لئے اجازت واپس لینے یا ترتیب دینے کے لئے معلومات حاصل کرنے کے لئے، براہ کرم ہماری پرائیویسی پالیسی دیکھیں۔",
    //   legalInformationHeadline: "قانونی معلومات",
    //   legalInformationSubHeadline:
    //     "اپنے حقوق اور ہماری پالیسیوں کے بارے میں مطلع رہیں",
    //   termsOfUseText: "استعمال کی شرائط",
    //   privacyPolicyText: "پرائیویسی پالیسی",
    //   cookiePolicy: "کوکی پالیسی",
    // },
    german: {
      align: "left",
      label: "Deutsch",
      headingText1: "DIE ZUKUNFT",
      readThisSection: "Diesen Abschnitt vorlesen",
      headingText2: "DER TECHNIK",
      headingText3: "IST EIN SPIEL.",
      subHeading:
        "Spiele sind eines der wichtigsten und zeitlosesten Medien der menschlichen Interaktion.",
      subsubHeading:
        "Wir entwickeln Apps, die die Art und Weise, wie wir mit der Welt von morgen interagieren, spielerisch neu gestalten.",
      commitmentHeading: "UNSER VERSPRECHEN",
      commitmentSubHeading: "Von Werten geleitet, von Zielen getrieben",
      commitmentSubSubHeading:
        "Mit einem klaren Ziel vor Augen entwickeln wir innovative Apps, die das Leben verbessern, den Einzelnen ermächtigen und eine bessere Zukunft für alle gestalten. Unsere Arbeit wird von Prinzipien der moralischen Exzellenz und Integrität geleitet und garantiert Ehrlichkeit und ethisches Verhalten in all unseren Geschäftsbeziehungen. Dieses Versprechen durchdringt jede Interaktion und fördert das Vertrauen unserer Nutzer und Stakeholder.",
        commitmentSubSubHeading2:"Im Kern unserer Mission liegt der tiefe Wunsch, einen spürbaren positiven Einfluss zu erzielen, der uns dazu antreibt, Neues zu erfinden und sinnvoll zur Gesellschaft beizutragen. Wir blicken über den Profit hinaus und konzentrieren unsere Kreativität und Fachkenntnisse darauf, Lösungen zu entwickeln, die nicht nur die Lebensqualität verbessern, sondern auch den Nutzern die notwendigen Werkzeuge in die Hände geben, die sie wirklich brauchen. Mit unseren Apps wollen wir echte Herausforderungen überwinden und eine vernetztere und digitalisierte globale Gemeinschaft fördern.",
        innovationHeading: "INNOVATION FÖRDERN",
        innovationSubHeading: "Ihre individuellen Softwarelösungen",
        innovationSubSubHeading1: "Indem wir unsere Mission auf neue Horizonte ausweiten, bieten wir einen spezialisierten Dienst für die Entwicklung individueller Softwarelösungen an. Dieses Vorhaben basiert auf unserem Engagement für Innovation und Qualität und zielt darauf ab, die Interaktion von Unternehmen und Einzelpersonen mit Technologie zu transformieren. Wir nutzen die neuesten technischen Fortschritte, um Software zu erstellen, die nicht nur nutzerzentriert ist, sondern auch als Motor für positive Veränderung dient und unsere Vision einer gestärkten Gesellschaft widerspiegelt.",
        innovationSubSubHeading2: "Jedes Projekt ist ein Schritt in Richtung Lösung spezifischer Herausforderungen, unterstützt durch unser umfassendes Fachwissen und ein fundiertes Verständnis des digitalen Ökosystems. Mit sorgfältiger Entwicklung und Zusammenarbeit zielen wir darauf ab, Softwarelösungen zu entwickeln, die die Erwartungen übertrifft, indem wir sicherstellen, dass unsere Lösungen nicht nur effektiv, sondern auch Maßstäbe für technologische Exzellenz und Verantwortung setzen.",
        pioneersHeading: "PIONIERE GESUCHT",
        pioneersSubHeading: "Bereit, das Unbekannte zu gestalten?",
        pioneersSubSubHeading1: "Wir suchen die Mutigen und die Kreativen. Unsere Reise ist eine der Entdeckung, Innovation und der Entwicklung von Lösungen, die die Grenzen dessen, was Technologie leisten kann, erweitern. Gemeinsam meistern wir die Herausforderungen von heute und morgen, indem wir einflussreiche Technologien schaffen, die eine weltweite Gemeinschaft stärken und inspirieren.",
        pioneersSubSubHeading2: "Deine Fachkenntnisse und Vision sind der Schlüssel, um neue Möglichkeiten zu erschließen und uns auf eine Zukunft zuzubewegen, in der Technologie das menschliche Potenzial verstärkt. Wir bauen nicht nur Software; wir gestalten die digitale Zukunft neu und laden dich ein, dich uns anzuschließen.",
      whatsNextHeading: "ENTDECKE WAS ALS NÄCHSTES KOMMT",
      whatsNextSubHeading: "Heutige Innovationen, morgige Standards",
      whatsNextSubSubHeading:
        "EasyConsent ist eine intuitive und sichere Familien-App, die deine Daten und Privatsphäre respektiert und dafür sorgt, dass deine digitalen Vollmachten einfach zu erstellen und zu verwalten sind, egal wann und wo du bist.",
      contactSectionHeading: "KONTAKTIERE UNS",
      contactSectionSubHeading: "Lass uns über deine Ideen sprechen",
      ideasText: "Your ideas and investment can shape the future of technology. Whether you're an enthusiastic user, an investor looking to make an impact, or a potential partner, we're excited to hear from you.",
      namePlaceholder: "Dein Name",
      emailPlaceholder: "Deine Email",
      messagePlaceholder: "Deine Nachricht",
      submitButtonText: "SCHREIB UNS EINE NACHRICHT",
      privacyText:
        "Wir respektieren Ihre Privatsphäre. Indem Sie uns eine Nachricht senden, stimmen Sie ausdrücklich unseren Nutzungsbedingungen zu und erkennen unsere Datenschutzrichtlinie und Cookie-Richtlinie an. Ihre Informationen werden verwendet, um auf Ihre Anfrage zu antworten und wie in unserer Datenschutzrichtlinie beschrieben. Informationen dazu, wie Sie Ihre Zustimmung widerrufen oder Ihre Daten ändern können, finden Sie in unserer Datenschutzrichtlinie.",
      legalInformationHeadline: "RECHTLICHE INFORMATIONEN",
      legalInformationSubHeadline:
        "Bleibe informiert über deine Rechte und unsere Richtlinien",
      termsOfUseText: "Nutzungsbedingungen",
      privacyPolicyText: "Datenschutzrichtlinie",
      cookiePolicy: "Cookie-Richtlinie",
    },
  },
  privacy: {
    english: {
      align: "left",
      label: "English",
      updated: "Updated",
      date: "December 12, 2023",
      welcome:
        "Welcome to VIZION. We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your information when you use our website.",
      personalInfoTitle: "Personal Information We Collect",
      personalInfoSub: "We collect the following personal information:",
      bullet1: "Name: Provided by you for personalised communication.",
      bullet2: "Email Address: Used to respond to your inquiries and feedback.",
      bullet3:
        "Message Content: Any information you include in our message center.",
      collectInfoTitle: "How We Collect Your Information",
      collectedInfoBullet1:
        "Directly Provided Information: Through our message center and any forms you fill out on our website.",
      collectedInfoBullet2:
        "Analytics and Tracking Technologies: We use Google Analytics and Hotjar to understand website usage, which involves collecting anonymised data.",
      useCollectedTitle: "Use of Collected Information",
      useBullet1:
        "Communication: To respond to your inquiries and provide support.",
      useBullet2:
        "Website Improvement: To enhance functionality and user experience based on usage data.",
      dataSharingTitle: "Data Sharing and Disclosure",
      dataSharingBullet1:
        "Service Providers: Information may be shared with third-party service providers for website operation and communication purposes.",
      dataSharingBullet2:
        "Legal Requirements: We may disclose your information if required by law or to protect our rights and safety.",
      dataSecurityTitle: "Data Security",
      dataSecurityText:
        "We implement security measures to protect your data but cannot guarantee absolute security against unauthorised access or breaches.",
      userRightsTitle: "User Rights",
      userRightsText:
        "You have the right to access, correct, or request deletion of your personal information. Please contact us for such requests.",
      cookiesTitle: "Cookies and Tracking Technologies",
      cookiesBullet1:
        "Use of Cookies: For website functionality and analytics. You can manage cookie preferences in your browser settings.",
      cookiesBullet2:
        "Analytics Tools: Information collected through Google Analytics and Hotjar is anonymised.",
      internationalTitle: "International Users",
      internationalText:
        "For users outside Spain, please note that your information will be processed in accordance with Spanish law.",
      changesTitle: "Changes to the Privacy Policy",
      changesText:
        "We may update this policy periodically. We encourage you to review it regularly to stay informed about our privacy practices.",
      contactUsTitle: "Contact Us",
      contactUsText:
        "For any questions or concerns about this Privacy Policy, please contact us at privacy@vizion.es.",
    },
    german: {
      align: "left",
      label: "Deutsch",
      updated: "Aktualisiert am",
      date: "12. Dezember 2023",
      welcome:
        "Willkommen bei VIZION. Wir respektieren deine Privatsphäre und sind verpflichtet, deine persönlichen Informationen zu schützen. Diese Datenschutzrichtlinie erläutert, wie wir deine Informationen erfassen, verwenden und schützen, wenn du unsere Website nutzt.",
      personalInfoTitle: "Persönliche Informationen, die wir erfassen",
      personalInfoSub: "Wir erfassen die folgenden persönlichen Informationen:",
      bullet1:
        "Name: Von dir zur personalisierten Kommunikation bereitgestellt.",
      bullet2:
        "E-Mail-Adresse: Verwendet, um auf deine Anfragen und Feedback zu antworten.",
      bullet3:
        "Nachrichteninhalt: Alle Informationen, die du in unserem Nachrichtenzentrum inkludierst.",
      collectInfoTitle: "Wie wir deine Informationen erfassen",
      collectedInfoBullet1:
        "Direkt bereitgestellte Informationen: Durch unser Nachrichtenzentrum und alle Formulare, die du auf unserer Website ausfüllst.",
      collectedInfoBullet2:
        "Analyse- und Tracking-Technologien: Wir verwenden Google Analytics und Hotjar, um die Website-Nutzung zu verstehen, was die Erfassung anonymisierter Daten einschließt.",
      useCollectedTitle: "Verwendung der erfassten Informationen",
      useBullet1:
        "Kommunikation: Um auf deine Anfragen zu antworten und Unterstützung bereitzustellen.",
      useBullet2:
        "Verbesserung der Website: Zur Verbesserung der Funktionalität und Benutzererfahrung basierend auf Nutzungsdaten.",
      dataSharingTitle: "Datenweitergabe und Offenlegung",
      dataSharingBullet1:
        "Dienstleister: Informationen können mit Drittanbieter-Dienstleistern für den Betrieb der Website und Kommunikationszwecke geteilt werden.",
      dataSharingBullet2:
        "Gesetzliche Anforderungen: Wir können deine Informationen offenlegen, wenn dies gesetzlich vorgeschrieben ist oder um unsere Rechte und die Sicherheit zu schützen.",
      dataSecurityTitle: "Datensicherheit",
      dataSecurityText:
        "Wir setzen Sicherheitsmaßnahmen ein, um deine Daten zu schützen, können jedoch keine absolute Sicherheit gegen unbefugten Zugriff oder Verstöße garantieren.",
      userRightsTitle: "Nutzerrechte",
      userRightsText:
        "Du hast das Recht auf Zugang, Berichtigung oder Löschung deiner persönlichen Informationen. Bitte kontaktiere uns für solche Anfragen.",
      cookiesTitle: "Cookies und Tracking-Technologien",
      cookiesBullet1:
        "Verwendung von Cookies: Für die Website-Funktionalität und Analytik. Du kannst die Cookie-Einstellungen in deinen Browsereinstellungen verwalten.",
      cookiesBullet2:
        "Analysetools: Informationen, die durch Google Analytics und Hotjar erfasst werden, sind anonymisiert.",
      internationalTitle: "Internationale Nutzer",
      internationalText:
        "Für Nutzer außerhalb Spaniens beachte bitte, dass deine Informationen gemäß spanischem Recht verarbeitet werden.",
      changesTitle: "Änderungen der Datenschutzrichtlinie",
      changesText:
        "Wir können diese Richtlinie regelmäßig aktualisieren. Wir empfehlen dir, sie regelmäßig zu überprüfen, um über unsere Datenschutzpraktiken informiert zu bleiben.",
      contactUsTitle: "Kontakt",
      contactUsText:
        "Für Fragen oder Bedenken bezüglich dieser Datenschutzrichtlinie kontaktiere uns bitte unter privacy@vizion.es.",
    },
    spanish: {
      align: "left",
      label: "Español",
      updated: "Actualizado el",
      date: "12 de diciembre de 2023",
      welcome:
        "Bienvenido/a a VIZION. Respetamos tu privacidad y estamos comprometidos en proteger tu información personal. Esta Política de Privacidad explica cómo recopilamos, utilizamos y protegemos tu información cuando utilizas nuestro sitio web.",
      personalInfoTitle: "Información personal que recopilamos",
      personalInfoSub: "Recopilamos la siguiente información personal:",
      bullet1:
        "Nombre: Proporcionado por ti para una comunicación personalizada.",
      bullet2:
        "Dirección de correo electrónico: Utilizada para responder a tus consultas y comentarios.",
      bullet3:
        "Contenido de los mensajes: Cualquier información que incluyas en nuestro centro de mensajes.",
      collectInfoTitle: "Cómo recopilamos tu información",
      collectedInfoBullet1:
        "Información proporcionada directamente: A través de nuestro centro de mensajes y cualquier formulario que completes en nuestro sitio web.",
      collectedInfoBullet2:
        "Tecnologías de análisis y seguimiento: Utilizamos Google Analytics y Hotjar para comprender el uso del sitio web, lo que implica la recopilación de datos anonimizados.",
      useCollectedTitle: "Uso de la información recopilada",
      useBullet1:
        "Comunicación: Para responder a tus consultas y brindar soporte.",
      useBullet2:
        "Mejora del sitio web: Para mejorar la funcionalidad y la experiencia del usuario en función de los datos de uso.",
      dataSharingTitle: "Divulgación y compartición de datos",
      dataSharingBullet1:
        "Proveedores de servicios: La información puede compartirse con proveedores de servicios de terceros para el funcionamiento del sitio web y fines de comunicación.",
      dataSharingBullet2:
        "Requisitos legales: Podemos divulgar tu información si así lo exige la ley o para proteger nuestros derechos y seguridad.",
      dataSecurityTitle: "Seguridad de los datos",
      dataSecurityText:
        "Implementamos medidas de seguridad para proteger tus datos, pero no podemos garantizar una seguridad absoluta contra el acceso no autorizado o las violaciones.",
      userRightsTitle: "Derechos del usuario",
      userRightsText:
        "Tienes derecho a acceder, corregir o solicitar la eliminación de tu información personal. Por favor, contáctanos para realizar dichas solicitudes.",
      cookiesTitle: "Cookies y tecnologías de seguimiento",
      cookiesBullet1:
        "Uso de cookies: Para la funcionalidad del sitio web y fines analíticos. Puedes gestionar las preferencias de cookies en la configuración de tu navegador.",
      cookiesBullet2:
        "Herramientas de análisis: La información recopilada a través de Google Analytics y Hotjar está anonimizada.",
      internationalTitle: "Usuarios internacionales",
      internationalText:
        "Para usuarios fuera de España, ten en cuenta que tu información se procesará de acuerdo con la ley española.",
      changesTitle: "Cambios en la Política de Privacidad",
      changesText:
        "Podemos actualizar esta política periódicamente. Te recomendamos revisarla regularmente para mantenerte informado sobre nuestras prácticas de privacidad.",
      contactUsTitle: "Contacto",
      contactUsText:
        "Si tienes preguntas o inquietudes sobre esta Política de Privacidad, por favor contáctanos en privacy@vizion.es.",
    },
  },
  terms: {
    english: {
      align: "left",
      label: "English",
      updated: "Updated",
      date: "12/12/2023",
      welcome:
        "Welcome to the VIZION website. By accessing and using our website, you agree to these Terms of Use.",
      intellectualTitle: "Intellectual Property Rights",
      content:
        "The content on our website, including text, graphics, logos, and images, is the property of VIZION or used with permission. It is protected by intellectual property laws and may not be used, reproduced, or distributed without our prior written consent.",
      useTitle: "Use of the Website",
      useContent:
        "Our website is designed to provide information about our services and is intended for lawful purposes only. Any misuse or unauthorised use of the content or functionalities is strictly prohibited.",
      responsibilityTitle: "User Responsibilities",
      bullet1:
        "Contact Box: You must provide accurate and truthful information when using our contact box.",
      bullet2:
        "Prohibited Conduct: You may not use our website for any illegal, harmful, or abusive activities.",
      contentAccuracyTitle: "Content Accuracy",
      contentAccuracyContent:
        "While we strive to ensure that the information on our website is accurate, we cannot guarantee that all the information is always up-to-date or error-free.",
      linksTitle: "Links to Other Websites",
      linkesContent:
        "Our website may contain links to third-party websites. We are not responsible for the content or practices of these external sites.",
      modificationTitle: "Modification of Content",
      modificationContent:
        "We reserve the right to modify, update, or remove content from our website at our discretion.",
      warrantiesTitle: "Disclaimer of Warranties",
      warrantiesContent:
        'The website is provided "as is," and we make no warranties regarding its accuracy, completeness, or reliability. We disclaim all warranties to the fullest extent permitted by law.',
      limitationOfLiabilitiesTitle: "Limitation of Liability",
      limitationsContent:
        "VIZION will not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our website.",
      governingLawTitle: "Governing Law",
      governingContent:
        "These Terms of Use are governed by the laws of Spain, and any disputes arising from these Terms will be subject to the exclusive jurisdiction of the courts of Spain.",
      severabilityTitle: "Severability",
      severabilityContent:
        "If any part of these Terms is found to be unenforceable or invalid, the remainder will continue to be in effect.",
      waiverTitle: "Waiver",
      waiverContent:
        "Failure to enforce any part of these Terms does not constitute a waiver of our rights under these Terms.",
      contactUsTitle: "Contact Us",
      contactContent:
        "For any questions about these Terms of Use or for legal notices, please contact us at privacy@vizion.es.",
    },
    german: {
      align: "left",
      label: "Deutsch",
      updated: "Aktualisiert am",
      date: "12. Dezember 2023",
      welcome:
        "Willkommen auf der VIZION-Website. Durch den Zugriff auf unsere Website und deren Nutzung stimmst du diesen Nutzungsbedingungen zu.",
      intellectualTitle: "Geistiges Eigentum",
      content:
        "Der Inhalt unserer Website, einschließlich Text, Grafiken, Logos und Bilder, ist Eigentum von VIZION oder wird mit Genehmigung verwendet. Er ist durch geistige Eigentumsrechte geschützt und darf ohne unsere vorherige schriftliche Zustimmung nicht verwendet, reproduziert oder verteilt werden.",
      useTitle: "Nutzung der Website",
      useContent:
        "Unsere Website dient dazu, Informationen über unsere Dienstleistungen bereitzustellen und ist nur für rechtmäßige Zwecke vorgesehen. Jeglicher Missbrauch oder unbefugte Nutzung des Inhalts oder der Funktionen ist strengstens untersagt.",
      responsibilityTitle: "Nutzerpflichten",
      bullet1:
        "Kontaktformular: Du musst genaue und wahrheitsgemäße Informationen angeben, wenn du unser Kontaktformular verwendest.",
      bullet2:
        "Verbotenes Verhalten: Du darfst unsere Website nicht für illegale, schädliche oder missbräuchliche Aktivitäten nutzen.",
      contentAccuracyTitle: "Genauigkeit des Inhalts",
      contentAccuracyContent:
        "Obwohl wir sicherstellen, dass die Informationen auf unserer Website korrekt sind, können wir nicht garantieren, dass alle Informationen immer aktuell oder fehlerfrei sind.",
      linksTitle: "Links zu anderen Websites",
      linkesContent:
        "Unsere Website kann Links zu Websites Dritter enthalten. Wir sind nicht verantwortlich für den Inhalt oder die Praktiken dieser externen Websites.",
      modificationTitle: "Änderung des Inhalts",
      modificationContent:
        "Wir behalten uns das Recht vor, Inhalte auf unserer Website nach eigenem Ermessen zu ändern, zu aktualisieren oder zu entfernen.",
      warrantiesTitle: "Haftungsausschluss",
      warrantiesContent:
        'Die Website wird "wie besehen" zur Verfügung gestellt, und wir geben keine Garantien hinsichtlich ihrer Genauigkeit, Vollständigkeit oder Zuverlässigkeit ab. Wir lehnen alle Garantien im gesetzlich zulässigen Umfang ab.',
      limitationOfLiabilitiesTitle: "Haftungsbeschränkung",
      limitationsContent:
        "VIZION haftet nicht für indirekte, zufällige, besondere, Folge- oder Strafschäden, die im Zusammenhang mit deiner Nutzung unserer Website stehen.",
      governingLawTitle: "Geltendes Rech",
      governingContent:
        "Diese Nutzungsbedingungen unterliegen den Gesetzen Spaniens, und alle Streitigkeiten aus diesen Bedingungen unterliegen der ausschließlichen Zuständigkeit der Gerichte Spaniens.",
      severabilityTitle: "Salvatorische Klausel",
      severabilityContent:
        "Sollte ein Teil dieser Bedingungen für nicht durchsetzbar oder ungültig befunden werden, so bleibt der Rest in Kraft.",
      waiverTitle: "Verzicht",
      waiverContent:
        "Die Nichtdurchsetzung eines Teils dieser Bedingungen stellt keinen Verzicht auf unsere Rechte aus diesen Bedingungen dar.",
      contactUsTitle: "Kontakt",
      contactContent:
        "Für Fragen zu diesen Nutzungsbedingungen oder für rechtliche Hinweise kontaktiere uns bitte unter privacy@vizion.es.",
    },
    spanish: {
      align: "left",
      label: "Español",
      updated: "Actualizado el",
      date: "12 de diciembre de 2023",
      welcome:
        "Bienvenido/a a la página web de VIZION. Al acceder y utilizar nuestra página web, aceptas estas Condiciones de Uso.",
      intellectualTitle: "Derechos de Propiedad Intelectual",
      content:
        "El contenido de nuestra página web, incluyendo texto, gráficos, logotipos e imágenes, es propiedad de VIZION o se utiliza con permiso. Está protegido por las leyes de propiedad intelectual y no puede ser utilizado, reproducido o distribuido sin nuestro consentimiento previo por escrito.",
      useTitle: "Uso de la Página Web",
      useContent:
        "Nuestra página web está diseñada para proporcionar información sobre nuestros servicios y está destinada únicamente para fines legítimos. Cualquier uso indebido o no autorizado del contenido o funcionalidades está estrictamente prohibido.",
      responsibilityTitle: "Responsabilidades del Usuario",
      bullet1:
        "Formulario de Contacto: Debes proporcionar información precisa y veraz al utilizar nuestro formulario de contacto.",
      bullet2:
        "Conducta Prohibida: No puedes utilizar nuestra página web para actividades ilegales, perjudiciales o abusivas.",
      contentAccuracyTitle: "Exactitud del Contenido",
      contentAccuracyContent:
        "Aunque nos esforzamos por garantizar que la información de nuestra página web sea precisa, no podemos garantizar que toda la información esté siempre actualizada o sea libre de errores.",
      linksTitle: "Enlaces a Otras Páginas Web",
      linkesContent:
        "Nuestra página web puede contener enlaces a sitios web de terceros. No somos responsables del contenido o prácticas de estos sitios web externos.",
      modificationTitle: "Modificación del Contenido",
      modificationContent:
        "Nos reservamos el derecho de modificar, actualizar o eliminar contenido de nuestra página web a nuestra discreción.",
      warrantiesTitle: "Descargo de Responsabilidad",
      warrantiesContent:
        'La página web se proporciona "tal como está", y no ofrecemos garantías sobre su exactitud, integridad o confiabilidad. Renunciamos a todas las garantías en la medida permitida por la ley.',
      limitationOfLiabilitiesTitle: "Limitación de Responsabilidad",
      limitationsContent:
        "VIZION no será responsable de daños indirectos, incidentales, especiales, consecuentes o punitivos que surjan de o estén relacionados con tu uso de nuestra página web.",
      governingLawTitle: "Ley Aplicable",
      governingContent:
        "Estas Condiciones de Uso están sujetas a las leyes de España, y cualquier disputa derivada de estas condiciones estará sujeta a la jurisdicción exclusiva de los tribunales de España.",
      severabilityTitle: "Cláusula de Salvaguard",
      severabilityContent:
        "Si alguna parte de estas condiciones se considera inaplicable o inválida, el resto seguirá en vigor.",
      waiverTitle: "Renuncia",
      waiverContent:
        "La falta de aplicación de alguna parte de estas condiciones no constituye una renuncia a nuestros derechos en virtud de estas condiciones.",
      contactUsTitle: "Contáctanos",
      contactContent:
        "Si tienes preguntas sobre estas Condiciones de Uso o avisos legales, por favor contáctanos en privacy@vizion.es.",
    },
  },
  cookie: {
    english: {
      align: "left",
      label: "English",
      updated: "Updated",
      date: "December 12, 2023",
      welcome:
        'This Cookie Policy explains how VIZION ("we", "us", "our") uses cookies and similar technologies to recognise you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control our use of them.',
      cookieTitle: "What are Cookies?",
      cookieContent:
        "Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, as well as to provide reporting information.",
      whyUseCookieTitle: "Why Do We Use Cookies?",
      whyCookieContent:
        'We use cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as "essential" cookies. Other cookies enable us to track and target the interests of our users to enhance the experience on our website. Third parties serve cookies through our website for analytics and other purposes.',
      typesTitle: "Types of Cookies Used on Our Website",
      typeBullet1:
        "Essential Cookies: These cookies are strictly necessary to provide you with services available through our website and to use some of its features, such as access to secure areas.",
      typeBullet2:
        "Analytics and Performance Cookies: These cookies are used to collect information about traffic to our site and how users use our site. The information gathered does not identify any individual visitor and is aggregated. It includes the number of visitors to our site, the websites that referred them to our site, the pages they visited on our site, what time of day they visited our site, whether they have visited our site before, and other similar information. We use this information to help operate our site more efficiently, to gather broad demographic information, and to monitor the level of activity on our site.",
      controlCookieTitle: "How Can You Control Cookies?",
      controlCookieContant:
        "You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.",
      changesCookieTitle: "Changes to the Cookie Policy",
      changesCookieContant:
        "We may update this Cookie Policy from time to time in order to reflect changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.",
      contactUsTitle: "Contact Us",
      contactUsContant:
        "If you have any questions about our use of cookies or other technologies, please email us at privacy@vizion.es.",
    },
    german: {
      align: "left",
      label: "Deutsch",
      updated: "Aktualisiert am",
      date: "12. Dezember 2023",
      welcome:
        'Diese Cookie-Richtlinie erläutert, wie VIZION ("wir", "uns", "unser") Cookies und ähnliche Technologien verwendet, um dich zu erkennen, wenn du unsere Website besuchst. Sie erklärt, was diese Technologien sind, warum wir sie verwenden und deine Rechte zur Kontrolle ihrer Verwendung.',
      cookieTitle: "Was sind Cookies?",
      cookieContent:
        "Cookies sind kleine Dateien, die auf deinem Computer oder Mobilgerät platziert werden, wenn du eine Website besuchst. Cookies werden von Website-Betreibern weit verbreitet eingesetzt, um ihre Websites zu betreiben und Berichtsinformationen bereitzustellen.",
      whyUseCookieTitle: "Warum verwenden wir Cookies?",
      whyCookieContent:
        'Wir verwenden Cookies aus verschiedenen Gründen. Einige Cookies sind aus technischen Gründen erforderlich, damit unsere Website funktioniert, und wir bezeichnen sie als "essenzielle" Cookies. Andere Cookies ermöglichen es uns, die Interessen unserer Benutzer zu verfolgen und anzusprechen, um die Erfahrung auf unserer Website zu verbessern. Dritte setzen Cookies über unsere Website für Analyse- und andere Zwecke ein.',
      typesTitle: "Arten von Cookies auf unserer Website",
      typeBullet1:
        "Essenzielle Cookies: Diese Cookies sind streng erforderlich, um dir Dienste zur Verfügung zu stellen, die über unsere Website verfügbar sind, und um einige ihrer Funktionen zu nutzen, wie den Zugang zu sicheren Bereichen.",
      typeBullet2:
        "Analyse- und Leistungs-Cookies: Diese Cookies werden verwendet, um Informationen über den Verkehr auf unserer Website und die Verwendung unserer Website durch Benutzer zu sammeln. Die gesammelten Informationen identifizieren keinen einzelnen Besucher und werden aggregiert. Dazu gehören die Anzahl der Besucher unserer Website, die Websites, die sie auf unsere Website verwiesen haben, die von ihnen besuchten Seiten unserer Website, die Tageszeit, zu der sie unsere Website besucht haben, ob sie unsere Website zuvor besucht haben, und ähnliche Informationen. Wir verwenden diese Informationen, um unsere Website effizienter zu betreiben, um allgemeine demografische Informationen zu sammeln und um das Aktivitätsniveau auf unserer Website zu überwachen.",
      controlCookieTitle: "Wie kannst du Cookies kontrollieren?",
      controlCookieContant:
        "Du hast das Recht zu entscheiden, ob du Cookies akzeptieren oder ablehnen möchtest. Du kannst deine Cookie-Einstellungen im Cookie Consent Manager festlegen. Der Cookie Consent Manager ermöglicht es dir, auszuwählen, welche Kategorien von Cookies du akzeptierst oder ablehnst. Essenzielle Cookies können nicht abgelehnt werden, da sie streng erforderlich sind, um dir Dienste zur Verfügung zu stellen.",
      changesCookieTitle: "Änderungen der Cookie-Richtlinie",
      changesCookieContant:
        "Wir können diese Cookie-Richtlinie von Zeit zu Zeit aktualisieren, um Änderungen an den von uns verwendeten Cookies oder aus anderen betrieblichen, rechtlichen oder regulatorischen Gründen widerzuspiegeln. Bitte besuche diese Cookie-Richtlinie daher regelmäßig, um über unsere Verwendung von Cookies und verwandten Technologien informiert zu bleiben.",
      contactUsTitle: "Kontakt",
      contactUsContant:
        "Wenn du Fragen zur Verwendung von Cookies oder anderen Technologien hast, sende uns bitte eine E-Mail an privacy@vizion.es.",
    },
    spanish: {
      align: "left",
      label: "Español",
      updated: "Actualizado el",
      date: "12 de diciembre de 2023",
      welcome: "Esta Política de Cookies explica cómo VIZION (\"nosotros\", \"nos\", \"nuestro\") utiliza cookies y tecnologías similares para reconocerte cuando visitas nuestro sitio web. Explica qué son estas tecnologías, por qué las utilizamos y tus derechos para controlar su uso.",
      cookieTitle: "¿Qué son las cookies?",
      cookieContent: "Las cookies son pequeños archivos que se colocan en tu ordenador o dispositivo móvil cuando visitas un sitio web. Los propietarios de sitios web utilizan ampliamente las cookies para que sus sitios funcionen y para proporcionar información de informes.",
      whyUseCookieTitle: "¿Por qué utilizamos cookies?",
      whyCookieContent: "Utilizamos cookies por diversas razones. Algunas cookies son necesarias por motivos técnicos para que nuestro sitio web funcione, y las llamamos cookies \"esenciales\". Otras cookies nos permiten rastrear y dirigir los intereses de nuestros usuarios para mejorar la experiencia en nuestro sitio web. Terceros utilizan cookies a través de nuestro sitio web para fines de análisis y otros propósitos.",
      typesTitle: "Tipos de cookies en nuestro sitio web",
      typeBullet1: "Cookies esenciales: Estas cookies son estrictamente necesarias para proporcionarte servicios disponibles a través de nuestro sitio web y para utilizar algunas de sus funciones, como el acceso a áreas seguras.",
      typeBullet2: "Cookies de análisis y rendimiento: Estas cookies se utilizan para recopilar información sobre el tráfico en nuestro sitio web y el uso de nuestro sitio web por parte de los usuarios. La información recopilada no identifica a un visitante individual y se agrega. Incluye el número de visitantes de nuestro sitio web, los sitios web que los remitieron a nuestro sitio web, las páginas que visitaron en nuestro sitio web, la hora del día en que visitaron nuestro sitio web, si han visitado nuestro sitio web anteriormente y otra información similar. Utilizamos esta información para operar nuestro sitio web de manera más eficiente, recopilar información demográfica general y supervisar el nivel de actividad en nuestro sitio web.",
      controlCookieTitle: "¿Cómo puedes controlar las cookies?",
      controlCookieContant: "Tienes el derecho de decidir si deseas aceptar o rechazar las cookies. Puedes ejercer tus derechos de cookies configurando tus preferencias en el Gestor de Consentimiento de Cookies. El Gestor de Consentimiento de Cookies te permite seleccionar qué categorías de cookies aceptas o rechazas. Las cookies esenciales no pueden ser rechazadas, ya que son estrictamente necesarias para proporcionarte servicios.",
      changesCookieTitle: "Cambios en la Política de Cookies",
      changesCookieContant: "Podemos actualizar esta Política de Cookies periódicamente para reflejar cambios en las cookies que utilizamos u otras razones operativas, legales o regulatorias. Por lo tanto, te recomendamos que visites esta Política de Cookies regularmente para mantenerte informado sobre nuestro uso de cookies y tecnologías relacionadas.",
      contactUsTitle: "Contacto",
      contactUsContant: "Si tienes preguntas sobre el uso de cookies u otras tecnologías, por favor envíanos un correo electrónico a privacy@vizion.es.",
    },
  },
};

export const align = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
};

export const textAlign = {
  right: "end",
  left: "start",
  center: "center",
};
export * from "./AudioFiles";
import styled, { css } from "styled-components";
import { align, textAlign } from "../constants";

export const VizionText = styled.span`
    font-size:40px;
    font-family: Arial;
    cursor: pointer;
    color: ${props => props.theme.textColor};
    margin-top: ${props => props.sticky ? "60px" : "0px"}
    ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size:38px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size:36px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            font-size:34px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size:32px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size:28px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size:26px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size:24px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size:22px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size:22px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }
    `}
`;

export const StyledSelect = styled.select`
  /* Resetting default border */
  border: none !important;
  /* Resetting default styling for Firefox */
  -moz-appearance: none;
  /* Resetting default styling for Chrome, Safari, and Edge */
  -webkit-appearance: none;
  /* Removing default styling for IE */
  appearance: none;
  /* Optional: Add additional styling as needed */
  padding: 5px; /* Add padding to improve visual appearance */
  background-color: #f0f0f0; /* Add background color */
  /* Add any other styles you want to apply */

  margin-top: ${props => props.sticky ? "60px" : "0px"}
  padding: 0px !important;
  background-color: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.textColor};
  /* Style for the dropdown arrow in Firefox */
  &::-ms-expand {
    display: none;
  }

  /* Style for the dropdown arrow in Chrome, Safari, and Edge */
  &::-webkit-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  /* Style for the dropdown arrow in Chrome, Safari, and Edge */
  &::-webkit-dropdown-arrow {
    display: none;
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const MainHeadline = styled.span`
    color: ${props => props.theme.textColor};
    font-family: Arial;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    text-align:${props => align[props.align]};

    ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size:60px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size:60px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            font-size:60px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size:60px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size:50px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size:50px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size:38px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 430px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 414px) and (max-width: 430px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size:28px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size:28px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size:22px;
        }
    `}
`;

export const MainHeadlineWrapper = styled.div`
    margin-top: 100px;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:${props => align[props.align]};
`;

export const SubHeadlineWrapper = styled.div`
    margin-top: 20px;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:${props => align[props.align]};
`;

export const SubHeadline = styled.span`
    font-size: 40px;
    font-weight: 400;   
    color: ${props => props.theme.textColor};
    font-family: Arial;
    font-style: normal;
    line-height: normal;
    display: flex;
    text-align:${props => align[props.align]};
    background: linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background: -webkit-linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background: -moz-linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background: -o-linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background-size: 100% 100%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 1.2px transparent;

    ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size:40px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size:40px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            font-size:40px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size:40px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size:24px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }

        @media (min-width: 430px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }

        @media (min-width: 414px) and (max-width: 430px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size:20x;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size:18px;
        }
    `}
`;

export const SubSubHeadlineWrapper = styled.div`
    margin-top: 40px;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:${props => props.align === "left" ? "flex-start" : "flex-end"};

`;

export const SubSubHeadline = styled.span`
    font-size: 25px;
    font-weight: 400;   
    color: ${props => props.theme.subHeadingColor};
    font-family: Arial;
    font-style: normal;
    line-height: normal;
    display: flex;
    text-align:${props => props.align === "left" ? "left" :  props.align === "center" ? "center" : "right"};

    ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size:25px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size:25px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            font-size:25px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size:25px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size:18px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size:18px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size:18px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size:18px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size:18px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size:16px;
        }
    `}
`;

export const PageContainer = styled.div`
    background-color: ${props => props.theme.backgroundColor};
    padding: 80px;
    ::selection {
        color: black;
        background-color: #ffff00;
    }

    ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            padding-top: 70px;
            padding-bottom: 70px;
            padding-left: 64px;
            padding-right: 64px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            padding-top: 70px;
            padding-bottom: 70px;
            padding-left: 56px;
            padding-right: 56px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            padding-top: 60px;
            padding-bottom: 60px;
            padding-left: 48px;
            padding-right: 48px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            padding-top: 50px;
            padding-bottom: 50px;
            padding-left: 40px;
            padding-right: 40px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            padding-top: 40px;
            padding-bottom: 40px;
            padding-left: 30px;
            padding-right: 30px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            padding-top: 50px;
            padding-bottom: 50px;
            padding-left: 40px;
            padding-right: 40px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            padding-top: 32px;
            padding-bottom: 32px;
            padding-left: 24px;
            padding-right: 24px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            padding-top: 30px;
            padding-bottom: 30px;
            padding-left: 24px;
            padding-right: 24px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            padding-top: 28px;
            padding-bottom: 28px;
            padding-left: 20px;
            padding-right: 20px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 16px;
            padding-right: 16px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 16px;
            padding-right: 16px;
        }

        @media (max-width: 360px) {
            /* Add your styles for medium screens here */
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 16px;
            padding-right: 16px;
        }
    `}
`;

export const StyledContentWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            margin-top:70px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            margin-top:68px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            margin-top:60px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            margin-top:53px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            margin-top:57px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            margin-top:48px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            margin-top:42px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            margin-top:40px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            margin-top:84px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            margin-top:38px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            margin-top:32px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            margin-top:30px;
        }
    `}
`;

export const StyledText = styled.span`
  color: #aaa;
  font-family: Arial;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: ${(props) => props.margin || "0px"};
  text-align: ${(props) => textAlign[props.align]};

  ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size:36px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size:34px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            font-size:32px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size:28px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size:26px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size:24px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size:22px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size:20px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size:18px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size:18px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size:16px;
        }
    `}
`;

export const StyledHeading = styled.span`
  color: #aaa;
  font-family: Arial;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 25px;

  ${props => css`
  @media (min-width: 1440px) and (max-width: 1919px) {
      /* Add your styles for medium screens here */
      font-size:36px;
  }

  @media (min-width: 1366px) and (max-width: 1440px) {
      /* Add your styles for medium screens here */
      font-size:34px;
  }

  @media (min-width: 1280px) and (max-width: 1366px) {
      /* Add your styles for medium screens here */
      font-size:32px;
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
      /* Add your styles for medium screens here */
      font-size:30px;
  }

  @media (min-width: 800px) and (max-width: 1024px) {
      /* Add your styles for medium screens here */
      font-size:28px;
  }

  @media (min-width: 768px) and (max-width: 800px) {
      /* Add your styles for medium screens here */
      font-size:26px;
  }

  @media (min-width: 600px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      font-size:24px;
  }

  @media (min-width: 480px) and (max-width: 600px) {
      /* Add your styles for medium screens here */
      font-size:22px;
  }

  @media (min-width: 414px) and (max-width: 480px) {
      /* Add your styles for medium screens here */
      font-size:20px;
  }

  @media (min-width: 375px) and (max-width: 414px) {
      /* Add your styles for medium screens here */
      font-size:18px;
  }

  @media (min-width: 360px) and (max-width: 375px) {
      /* Add your styles for medium screens here */
      font-size:18px;
  }

  @media (min-width: 320px) and (max-width: 360px) {
      /* Add your styles for medium screens here */
      font-size:16px;
  }
`}
`;

export const StyledList = styled.li`
  color: #aaa;
  font-family: Arial;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            font-size:32px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            font-size:32px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            font-size:30px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            font-size:28px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            font-size:26px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            font-size:26px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size:24px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            font-size:24px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            font-size:22px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            font-size:22px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size:22px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            font-size:22px;
        }
    `}
`;

export const StyledUl = styled.ul`
  text-align: ${props => textAlign[props.align]};
`;

export const StickyNav = styled.div`
    position: ${props => props.sticky ? 'fixed' : 'relative'};
    top: 0;
    left: 0;
    z-index:999;
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    width:100%;
    background-color: ${props => props.theme.backgroundColor};
    box-sizing: border-box;
    padding-top: ${props => props.sticky  ? "80px" : "0px"};
    padding-bottom: ${props => props.sticky  ? "48px" : "0px"};
    padding-left: ${props => props.sticky  ? "64px" : "0px"};
    padding-right: ${props => props.sticky  ? "64px" : "0px"};


    ${props => {
        console.log(props.sticky);
        return css`

        @media screen and (max-width: 1919px) {
            padding-top:${props => props.sticky ? "70px" : "0px"};
            padding-bottom:${props => props.sticky ? "44px" : "0px"};
            padding-left:${props => props.sticky ? "64px" : "0px"};
            padding-right:${props => props.sticky ? "64px" : "0px"};
        }

        @media screen and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            padding-top:${props => props.sticky ? "70px" : "0px"};
            padding-bottom:${props => props.sticky ? "42px" : "0px"};
            padding-left:${props => props.sticky ? "56px" : "0px"};
            padding-right:${props => props.sticky ? "56px" : "0px"};
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            padding-top:${props => props.sticky ? "60px" : "0px"};
            padding-bottom:${props => props.sticky ? "40px" : "0px"};
            padding-left:${props => props.sticky ? "48px" : "0px"};
            padding-right:${props => props.sticky ? "48px" : "0px"};
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            padding-top:${props => props.sticky ? "50px" : "0px"};
            padding-bottom:${props => props.sticky ? "36px" : "0px"};
            padding-left:${props => props.sticky ? "40px" : "0px"};
            padding-right:${props => props.sticky ? "40px" : "0px"};
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            padding-top:${props => props.sticky ? "40px" : "0px"};
            padding-bottom:${props => props.sticky ? "34px" : "0px"};
            padding-left:${props => props.sticky ? "30px" : "0px"};
            padding-right:${props => props.sticky ? "30px" : "0px"};
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            padding-top:${props => props.sticky ? "50px" : "0px"};
            padding-bottom:${props => props.sticky ? "34px" : "0px"};
            padding-left:${props => props.sticky ? "40px" : "0px"};
            padding-right:${props => props.sticky ? "40px" : "0px"};
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            padding-top:${props => props.sticky ? "32px" : "0px"};
            padding-bottom:${props => props.sticky ? "32px" : "0px"};
            padding-left:${props => props.sticky ? "24px" : "0px"};
            padding-right:${props => props.sticky ? "24px" : "0px"};
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            padding-top:${props => props.sticky ? "30px" : "0px"};
            padding-bottom:${props => props.sticky ? "30px" : "0px"};
            padding-left:${props => props.sticky ? "24px" : "0px"};
            padding-right:${props => props.sticky ? "24px" : "0px"};
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            padding-top:${props => props.sticky ? "28px" : "0px"};
            padding-bottom:${props => props.sticky ? "28px" : "0px"};
            padding-left:${props => props.sticky ? "20px" : "0px"};
            padding-right:${props => props.sticky ? "20px" : "0px"};
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            padding-top:${props => props.sticky ? "24px" : "0px"};
            padding-bottom:${props => props.sticky ? "26px" : "0px"};
            padding-left:${props => props.sticky ? "16px" : "0px"};
            padding-right:${props => props.sticky ? "16px" : "0px"};
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            padding-top:${props => props.sticky ? "24px" : "0px"};
            padding-bottom:${props => props.sticky ? "26px" : "0px"};
            padding-left:${props => props.sticky ? "16px" : "0px"};
            padding-right:${props => props.sticky ? "16px" : "0px"};
        }

        @media (max-width: 360px) {
            /* Add your styles for medium screens here */
            padding-top:${props => props.sticky ? "20px" : "0px"};
            padding-bottom:${props => props.sticky ? "24px" : "0px"};
            padding-left:${props => props.sticky ? "16px" : "0px"};
            padding-right:${props => props.sticky ? "16px" : "0px"};
        }
    `
    }}

`;

export const NavContent = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
`;
import styled, { css } from "styled-components";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 240px;

  ${(props) => css`
    @media (min-width: 1440px) and (max-width: 1919px) {
      /* Add your styles for medium screens here */
      margin-top: 210px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      /* Add your styles for medium screens here */
      margin-top: 210px;
    }

    @media (min-width: 1280px) and (max-width: 1366px) {
      /* Add your styles for medium screens here */
      margin-top: 180px;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
      /* Add your styles for medium screens here */
      margin-top: 150px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      /* Add your styles for medium screens here */
      margin-top: 120px;
    }

    @media (min-width: 768px) and (max-width: 800px) {
      /* Add your styles for medium screens here */
      margin-top: 120px;
    }

    @media (min-width: 600px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      margin-top: 96px;
    }

    @media (min-width: 480px) and (max-width: 600px) {
      /* Add your styles for medium screens here */
      margin-top: 90px;
    }

    @media (min-width: 414px) and (max-width: 480px) {
      /* Add your styles for medium screens here */
      margin-top: 84px;
    }

    @media (min-width: 375px) and (max-width: 414px) {
      /* Add your styles for medium screens here */
      margin-top: 72px;
    }

    @media (min-width: 360px) and (max-width: 375px) {
      /* Add your styles for medium screens here */
      margin-top: 72px;
    }

    @media (min-width: 320px) and (max-width: 360px) {
      /* Add your styles for medium screens here */
      margin-top: 62px;
    }
  `}
`;

export const StyledText = styled.div`
  color: #aaa;
  text-align: center;
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  ${(props) => css`
    @media (min-width: 1440px) and (max-width: 1919px) {
      /* Add your styles for medium screens here */
      font-size:20px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      /* Add your styles for medium screens here */
      font-size:20px;
    }

    @media (min-width: 1280px) and (max-width: 1366px) {
      /* Add your styles for medium screens here */
      font-size:20px;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
      /* Add your styles for medium screens here */
      font-size:20px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      /* Add your styles for medium screens here */
      font-size:18px;
    }

    @media (min-width: 768px) and (max-width: 800px) {
      /* Add your styles for medium screens here */
      font-size:18px;
    }

    @media (min-width: 600px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      font-size:16px;
    }

    @media (min-width: 480px) and (max-width: 600px) {
      /* Add your styles for medium screens here */
      font-size:16px;
    }

    @media (min-width: 414px) and (max-width: 480px) {
      /* Add your styles for medium screens here */
      font-size:14px;
    }

    @media (min-width: 375px) and (max-width: 414px) {
      /* Add your styles for medium screens here */
      font-size:14px;
    }

    @media (min-width: 360px) and (max-width: 375px) {
      /* Add your styles for medium screens here */
      font-size:14px;
    }

    @media (min-width: 320px) and (max-width: 360px) {
      /* Add your styles for medium screens here */
      font-size:12px;
    }
  `};
`;

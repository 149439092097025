import React from "react";
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';

import Home from "./pages/home";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";

const Router = () => {

    return(
        <HashRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/terms" element={<TermsOfUse/>}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path="/cookie-policy" element={<CookiePolicy/>}/>
            </Routes>
      </HashRouter>
    )
}

export default Router;
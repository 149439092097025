import React from "react";
import { 
    MainHeadline,
    MainHeadlineWrapper,
    SubHeadlineWrapper,
    SubHeadline,
    SubSubHeadlineWrapper,
    SubSubHeadline
} from "../style";

import AudioComponent from "../AudioSpectrum";

const CommitmentSection = ({ langObject, audio }) => {
    return (
        <div>
            <MainHeadlineWrapper align={langObject.align}>
                <MainHeadline align={langObject.align}>{langObject.commitmentHeading || ""}</MainHeadline>
            </MainHeadlineWrapper>
            <SubHeadlineWrapper align={langObject.align}>
                <SubHeadline align={langObject.align}>{langObject.commitmentSubHeading || ""}</SubHeadline>
            </SubHeadlineWrapper>
            <SubSubHeadlineWrapper align={langObject.align}>
                <SubSubHeadline align={langObject.align}>{langObject.commitmentSubSubHeading || ""}</SubSubHeadline>
                <br/>
                <SubSubHeadline align={langObject.align}>{langObject.commitmentSubSubHeading2 || ""}</SubSubHeadline>
            </SubSubHeadlineWrapper>
            <AudioComponent text={langObject.readThisSection} audioFile={audio} />
        </div>
    );
}

export default CommitmentSection;

import React from "react";
import PageContainer from "../../components/PageContainer";

import HomePageBanner from "../../components/HomePageBanner/HomePageBanner";
import CommitmentSection from "../../components/CommitmentSection";
import WhatsNextSection from "../../components/whatsNext";
import Carousal from "../../components/Carousal";
import InnovationsSection from "../../components/InnovationsSection";
import PioneersSection from "../../components/PioneersSection";
import ContactSection from "../../components/ContactSection";
import LegalSection from "../../components/LegalSection";

import useGetLanguage from "../../hooks/useGetLanguage";
import useGetAudio from "../../hooks/useGetAudio";
import {
  SectionSeparator
} from "./styles";

const Home = () => {
  const langObject = useGetLanguage("home");
  const audioObject = useGetAudio("home");
  return (
    <PageContainer page={"home"}>
      <HomePageBanner langObject={langObject} audio={audioObject.home} />
      <SectionSeparator />
      <CommitmentSection langObject={langObject} audio={audioObject.commitment} />
      <SectionSeparator />
      {/* <WhatsNextSection langObject={langObject} /> */}
      {/* <Carousal /> */}
      <InnovationsSection langObject={langObject} audio={audioObject.empowering} />
      <SectionSeparator />
      <PioneersSection langObject={langObject} audio={audioObject.pioneers} />
      <SectionSeparator />
      <ContactSection langObject={langObject} audio={audioObject.getInTouch} />
      <SectionSeparator />
      <LegalSection langObject={langObject} audio={audioObject.legal} />
    </PageContainer>
  );
};

export default Home;

import React from "react";
import PageContainer from "../../components/PageContainer";

import BannerTOU from "../../components/BannerTOU";
import ContentPrivacy from "../../components/ContentPrivacy";

import useGetLanguage from "../../hooks/useGetLanguage";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
  const langObject = useGetLanguage("privacy");
  const location = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, [location.pathname]);
  return (
    <PageContainer page={"privacy"}>
      <BannerTOU langObject={langObject} align={langObject.align} text={"PRIVACY POLICY FOR VIZION"} />
      <ContentPrivacy langObject={langObject} />
    </PageContainer>
  );
};

export default PrivacyPolicy;

import styled, { css } from "styled-components";
import { Carousel } from 'antd';

export const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${props => props.theme.inActiveDotColor};
  }
  > .slick-dots li.slick-active button {
    width: 11px;
    height: 11px;
    border-radius: 100%;
    background: ${props => props.theme.textColor};
  }
`;

export const ImageWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-bottom:50px;
    margin-top:124px;

    ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            margin-bottom:48px;
            margin-top:120px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            margin-bottom:46px;
            margin-top:116px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            margin-bottom:44px;
            margin-top:112px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            margin-bottom:42px;
            margin-top:108px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            margin-bottom:40px;
            margin-top:104px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            margin-bottom:38px;
            margin-top:100px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            margin-bottom:36px;
            margin-top:96px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            margin-bottom:34px;
            margin-top:92px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            margin-bottom:33px;
            margin-top:88px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            margin-bottom:30px;
            margin-top:84px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            margin-bottom:30px;
            margin-top:84px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            margin-bottom:28px;
            margin-top:80px;
        }
    `}
`;

export const StyledImage = styled.img`
  width: 450px;
  height: 916px;

  ${props => css`
        @media (min-width: 1440px) and (max-width: 1919px) {
            /* Add your styles for medium screens here */
            width: 414px;
            height: 855px;
        }

        @media (min-width: 1366px) and (max-width: 1440px) {
            /* Add your styles for medium screens here */
            width: 405px;
            height: 835px;
        }

        @media (min-width: 1280px) and (max-width: 1366px) {
            /* Add your styles for medium screens here */
            width: 495px;
            height: 814px;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            /* Add your styles for medium screens here */
            width: 346px;
            height: 713px;
        }

        @media (min-width: 800px) and (max-width: 1024px) {
            /* Add your styles for medium screens here */
            width: 326px;
            height: 671px;
        }

        @media (min-width: 768px) and (max-width: 800px) {
            /* Add your styles for medium screens here */
            width: 316px;
            height: 651px;
        }

        @media (min-width: 600px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            width: 257px;
            height: 529px;
        }

        @media (min-width: 480px) and (max-width: 600px) {
            /* Add your styles for medium screens here */
            width: 246px;
            height: 506px;
        }

        @media (min-width: 414px) and (max-width: 480px) {
            /* Add your styles for medium screens here */
            width: 246px;
            height: 506px;
        }

        @media (min-width: 375px) and (max-width: 414px) {
            /* Add your styles for medium screens here */
            width: 222px;
            height: 458px;
        }

        @media (min-width: 360px) and (max-width: 375px) {
            /* Add your styles for medium screens here */
            width: 217px;
            height: 448px;
        }

        @media (min-width: 320px) and (max-width: 360px) {
            /* Add your styles for medium screens here */
            width: 197px;
            height: 407px;
        }
    `}
`;
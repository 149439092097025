import React,{useMemo, useEffect, useState} from "react";
import { languages } from "../constants";

const useGetLanguage = (page) => {
    const [lang, setLang] = useState("");
    useEffect(() => {
        // Update the value when the storage event is fired
        setLang(localStorage.getItem("lang") || "english");
        const handleStorageChange = () => {
            setLang(localStorage.getItem('lang') || '');
        };
    
        // Listen for the storage event
        window.addEventListener('storage', handleStorageChange);
    
        // Clean up the event listener when the component is unmounted
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
      }, []);
    return useMemo(() => {
        const langObject = languages[page];
        return langObject[lang] ? langObject[lang]: langObject["english"];
    },[lang, page]);
}

export default useGetLanguage;